<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

     <vs-table ref="table" multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="contests">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">

          <!-- ACTION - DROPDOWN -->
         <!-- deleted because the use case for selecting more than one is not present, and very rare. -->


          <!-- ADD NEW -->
          <router-link class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary" to="/contests/new">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">إضافة مسابقة</span>
          </router-link>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ contests.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : contests.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="id">رقم المسابقة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="brand">الشركة</vs-th>
        <vs-th sort-key="start_date">تاريخ البداية</vs-th>
        <vs-th sort-key="end_date">تاريخ النهاية</vs-th>
        <vs-th sort-key="isActive">الحالة</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="contest" v-for="(contest, index) in data" :key="index">

              <!-- in all these place holders replace them with {{contest.ObjectHere}} -->

            <vs-td class="img-container">
              <img :src="contest.full_path"  class="contest-picture" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate"> {{contest.id}} </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate"> {{contest.name}} </p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{contest.brand.name}}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{contest.start_date}}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{contest.end_date}}</p>
            </vs-td>
            <vs-td>
              <vs-switch val="html" v-model="contest.is_active" @change="changeContestStatus(contest.id,contest.is_active)"> Html </vs-switch>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <router-link :to="{name: 'edit-contest', params: {id: contest.id}  }">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
              </router-link>

              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteContest(contest.id)" />
            </vs-td>

          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import axios from "@/axios.js"
import moduleContest from "@/store/contests/moduleContest.js";



export default {
  components: {
    axios
  },
  data() {
    return {
      isMounted: false,
      selected: [],
      current_Page: 1,
      itemsPerPage: 4,
      lastPage: 0,
      totalItems: 0,


      //manually filled array of data
      contests: [],

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },

    queriedItems() {
      return this.$refs.table
      ? this.$refs.table.queriedResults.length
      : this.contests.length
    }
  },
  methods: {
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    },
    getData(page){
      this.$store.dispatch("contest/fetchContests",page).then((response) => {
      this.$vs.loading.close()
      this.contests = response.data.contests;

    });
    },
    deleteContest(id){
      this.$vs.dialog({
          type: 'confirm',
          title: "تحذير!",
          text: "أنت على وشك حذف هذه المسابقة هل أنت متأكد؟ " ,
          color: "danger",
          acceptText: "حذف",
          cancelText: "إلغاء",
          accept: ()=>this.deleteData(id)
        });
    },
    deleteData(id){
      this.$store.dispatch("contest/deleteContest",id).then(()=>{
                  this.$vs.notify({
                    title: "تم بنجاح",
                    text: "تم حذف المسابقة",
                    color: "success",
                  });
                  this.getData(1);

                }).catch(()=>{
                  this.$vs.notify({
                    title: "حدث خطأ",
                    text: "يرجى اعادة المحاولة",
                    color: "danger",
                  });
                });
    },
    changeContestStatus(id, isActive){
        var payloadData = new FormData();
              payloadData.set("id", id);
              payloadData.set("status", isActive? 1 : 0);

      this.$store.dispatch("contest/changeContestStatus", payloadData)
      .then(()=>{
                  this.$vs.notify({
                    title: "تم بنجاح",
                    text: "تم تغيير حالة المسابقة",
                    color: "success",
                  });

                })
      .catch(()=>{
                  this.$vs.notify({
                    title: "حدث خطأ",
                    text: "يرجى اعادة المحاولة",
                    color: "danger",
                  });
                });

    }
  },
  created() {
    if (!moduleContest.isRegistered) {
      this.$store.registerModule("contest", moduleContest);
      moduleContest.isRegistered = true;
    }

  },
  beforeMount()
  {
    this.getData(1);
  }

  ,
  mounted() {
    this.$vs.loading()
    this.isMounted = true;
  }
}
</script>








<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }
    .contest-picture{
      max-width:100px;
      max-height:68px;
    }
    .img-container{
      height: 100px;
      max-width:100px;
      max-height:100px;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
